import { forwardRef, LegacyRef } from "react";

import { TickSmall } from "./icons";

export declare interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  label: string;
  onChange?: (value: boolean) => void;
  value?: string;
}

function Checkbox(
  { checked, disabled, label, onChange, value }: CheckboxProps,
  ref: LegacyRef<HTMLInputElement> | undefined,
) {
  return (
    <label className="relative flex w-fit items-center">
      <input
        className="peer h-5 w-5 appearance-none rounded-sm border border-graphite focus:border-iron disabled:border-aluminum disabled:bg-milk"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e.target.checked)}
        ref={ref}
        type="checkbox"
        value={value}
      />
      <TickSmall className="pointer-events-none absolute -left-[0.125rem] hidden text-iron peer-checked:block peer-disabled:text-granite" />
      <div className="ml-2 text-granite peer-checked:text-iron peer-disabled:text-storm">
        {label}
      </div>
    </label>
  );
}

export default forwardRef(Checkbox);
